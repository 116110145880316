<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card" v-else>
      <div class="card-body">
        <form @submit.prevent="submit" class="form-horizontal">
          <div class="form-body">
            <h3 class="box-title">Meeting</h3>
            <hr class="m-t-0 m-b-40" />

            <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.nama_ruangan"
                  name="nama_ruangan"
                  required
                  type="text"
                  label="Nama Ruangan"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label
                    :class="[
                      'control-label',
                      { 'text-right': !isMobile },
                      'col-md-3',
                    ]"
                  >
                    OPD
                  </label>
                  <div :class="['col-md-9', { invalid: isinvalidOpd }]">
                    <Multiselect
                      placeholder="Type to Search"
                      select-label
                      :value="payload.valueOpd"
                      track-by="value"
                      label="text"
                      :options="optionOpd"
                      :searchable="true"
                      :allow-empty="false"
                      :hide-selected="true"
                      :max-height="150"
                      :disabled="isDisabled"
                      :block-keys="['Tab', 'Enter']"
                      @input="onChangeOpd"
                      @close="onTouch"
                      @search-change="unitFindOpd"
                    ></Multiselect>
                    <label class="typo__label form__label" v-show="isinvalidOpd"
                      >Must have at least one value</label
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label
                    :class="[
                      'control-label',
                      { 'text-right': !isMobile },
                      'col-md-3',
                    ]"
                    >Tipe Ruangan</label
                  >
                  <div class="col-md-9 col-sm-9">
                    <div class="form-check">
                      <label class="form-check-label">
                        <input
                          v-model="payload.isTipe"
                          type="radio"
                          value="1"
                          class="form-check-input"
                          name="optradiom"
                        />Khusus
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input
                          v-model="payload.isTipe"
                          type="radio"
                          value="0"
                          class="form-check-input"
                          name="optradiom"
                        />Umum
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.kapasitas_ruangan"
                  name="kapasitas_ruangan"
                  type="text"
                  required
                  label="Kapasitas Ruangan"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.fasilitas_ruangan"
                  name="fasilitas_ruangan"
                  type="text"
                  required
                  label="Fasilitas Ruangan"
                 />
              </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label :class="['control-label']"
                      >File Ruangan</label
                    >
                    <h6>
                      File Ruangan dalam format PDF atau JPG/JPEG/PNG dengan
                      ukuran maksimal 20MB
                    </h6>
                    <h6 v-if="!files.length">{{ $t("no_file") }}</h6>
                    <table v-else class="table table-stripped">
                      <tr>
                        <th>{{ $t("file") }}</th>
                        <th>{{ $t("action") }}</th>
                      </tr>
                      <tr v-for="file in files" v-bind:key="file.filename">
                        <td>
                          {{
                            file.filepath !== undefined
                              ? file.filename
                              : file.name
                          }}
                        </td>
                        <td>
                          <span v-if="file.filepath !== undefined">
                            <a
                              class="btn btn-success text-white"
                              @click="download(file.uuid, file.filename)"
                              ><i class="fa fa-download text-white"></i>
                            </a>
                            <a
                              class="btn btn-info text-white"
                              @click="prev(file.uuid)"
                              ><i class="fa fa-eye text-white"></i>
                            </a>
                          </span>
                          <i
                            ><b>{{ $t("not_saved") }} &nbsp;&nbsp;</b></i
                          >
                          <a
                            class="btn btn-danger text-white"
                            @click="removeAttachment(file.uuid)"
                          >
                            <i class="fa fa-trash-o text-white"></i>
                          </a>
                        </td>
                      </tr>
                    </table>
                    <Gallery :uploader="uploader" />
                  </div>
                </div>
              </div>

          </div>
          <hr />
          <div class="form-actions">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-offset-3 col-md-9">
                    <button type="submit" class="btn btn-success">Simpan</button
                    >&nbsp;
                    <button
                      type="button"
                      @click="goBack()"
                      class="btn btn-inverse"
                    >
                      Batal
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-6"></div>
            </div>
          </div>
        </form>
        <ModalDefault
          v-show="isVisible"
          :title="modalTitle"
          :content="modalContent"
          :action="ActionChangeable"
          :confirmation="isConfirmation"
          @close="onClose"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { RotateSquare5 } from "vue-loading-spinner";
import Input from "@/components/input/input";
import Multiselect from "vue-multiselect";
import Upload from "@/components/input/upload.vue";
import axios from "axios";

export default {
  components: {
    Input,
    RotateSquare5,
    Upload,
    Multiselect,
  },
  computed: {
    editMode() {
      return !!this.$route.params.id;
    },
    state() {
      return this.$store.state.meeting;
    },
    isinvalidOpd() {
      return this.isTouchedOpd && this.payload.valueOpd.length === 0;
    },
    optionJenisDokumen() {
      const jenisdokumen = JSON.parse(localStorage.getItem("refUnit"));
      const jenisdokumenDropdown = [];
      if (jenisdokumen) {
        jenisdokumen.forEach((value) => {
          jenisdokumenDropdown.push({ id: value.id, text: value.name });
        });
      }

      return jenisdokumenDropdown;
    },
    optionSifatDokumen() {
      const sifatdokumen = JSON.parse(localStorage.getItem("refUnit"));
      const sifatdokumenDropdown = [];
      if (sifatdokumen) {
        sifatdokumen.forEach((value) => {
          sifatdokumenDropdown.push({ id: value.id, text: value.name });
        });
      }

      return sifatdokumenDropdown;
    },
    isInvalidBahasa() {
      return this.isTouched && this.payload.valueBahasa.length === 0;
    },
    isInvalidJenisDokumen() {
      return (
        this.isTouchedJenisDokumen &&
        this.payload.valueJenisDokumen.length === 0
      );
    },
    isInvalidSifatDokumen() {
      return (
        this.isTouchedSifatDokumen &&
        this.payload.valueSifatDokumen.length === 0
      );
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
  data() {
    const uploader = this.fineUploader(this, "meeting");

    return {
      uploader,
      options: [
        "Select option",
        "Disable me!",
        "Reset me!",
        "Admin Dinas Komunikasi dan Informatika ( Kepala Seksi Dinas Komunikasi dan Informatika )",
        "label",
        "searchable",
      ],
      payload: {
        nama_ruangan: "",
        valueOpd: [],
        kapasitas_ruangan: "",
        isTipe: "0",
        fasilitas_ruangan: "",
      },
      myFile: [],
      files: [],
      isDisabled: false,
      optionOpd: [],
      idLoading: false,
    };
  },
  async mounted() {
    this.unitFindOpd("");
    this.initialize();
  },

  methods: {
    async initialize() {
      try {
        if (this.editMode) {
          axios.get(`/meeting/${this.$route.params.id}`).then((response) => {
            const state = {
              loaded: true,
            };
            this.$store.commit("meeting/STATE", state);
            this.edit(response.data.data2);
          });
        } else {
          this.$store.dispatch("meeting/onAdd");
        }
      } catch (e) {
        this.handleApiError(e);
        this.error = e.message;
      }
    },
    edit(data2) {
      this.payload = {
        nama_ruangan: data2.nama_ruangan,
        kapasitas_ruangan: data2.kapasitas_ruangan,
        isTipe: data2.isTipe,
        fasilitas_ruangan: data2.fasilitas_ruangan,
      };
    },
    onTouchJenisDokumen() {
      this.isTouchedJenisDokumen = true;
    },
    onTouchSifatDokumen() {
      this.isTouchedSifatDokumen = true;
    },
    onChangeOpd(value) {
      this.payload.valueOpd = value;
    },
    onChangeBahasa(value) {
      this.payload.valueBahasa = value;
      if (value.indexOf("Reset me!") !== -1) this.payload.valueBahasa = [];
    },
    onChangeJenisDokumen(value) {
      this.payload.valueJenisDokumen = value;
      if (value.indexOf("Reset me!") !== -1)
        this.payload.valueJenisDokumen = [];
    },
    onChangeSifatDokumen(value) {
      this.payload.valueSifatDokumen = value;
      if (value.indexOf("Reset me!") !== -1)
        this.payload.valueSifatDokumen = [];
    },
    onSelect(option) {
      if (option === "Disable me!") this.isDisabled = true;
    },
    goBack() {
      this.$store.dispatch("meeting/onCancel");
    },
    unitFindOpd(query) {
      this.isLoading = true;
      axios
        .get(`units/listmeeting?s=${query}`)
        .then((res) => {
          this.optionOpd = res.data.items;
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$store.commit("auth/LOGOUT");
          }
        });
    },
    submit() {

      const payload = {
        nama_ruangan: this.payload.nama_ruangan,
        id_unit: parseInt(this.payload.valueOpd.value),
        kapasitas_ruangan: this.payload.kapasitas_ruangan,
        is_tipe: this.payload.isTipe,
        fasilitas_ruangan: this.payload.fasilitas_ruangan,
        files: this.files,
      };
      const data = JSON.stringify(payload);
      this.$validator.validateAll().then((success) => {
        if (success) {
          if (this.editMode) {
            this.$store.dispatch("meeting/submitEdit", {
              data,
              id: this.$route.params.id,
            });
          } else {
            this.$store.dispatch("meeting/submitAdd", data);
          }
        }
      });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border: 1px solid #ced4da !important;
}
.invalid .multiselect__tags {
  border-color: #f04124 !important;
  padding: 5px 40px 0 8px !important;
}
.invalid .typo__label {
  color: #f04124;
  font-size: 0.8125rem;
}
.multiselect {
  color: #54667a !important;
}
.multiselect__select {
  height: 41px !important;
}
</style>

